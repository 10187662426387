import React, { useState, useRef } from "react";

import channelStyles from "../../../../css/layout/chat/channel-list.css";
import formStyles from "../../../../css/form.css";

import ProfilePicture from "../../layout/parts/user/ProfilePicture";
import useLongPress from "../../../../util/hooks/useLongPress";
import outsideClick from "../../../../util/components/outsideClick";

import CloseChat from "../../../../assets/svg/icons/times.svg";

import { formatDistanceToNow } from 'date-fns';

export function Person(props) {
    const optionsRef = useRef(null);
    const [personOptions, setPersonOptions] = useState(false);
    
    function shortenText(text){
        if(text.length > 25){
            return text.slice(0, 25) + "..."
        } else {
            return text
        }
    }

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp);
        return formatDistanceToNow(date, { addSuffix: true });
    }

    function handleCloseChatClick(event) {
        event.stopPropagation();
        try {
            props.onCloseChat(props.user.info.id);
        } catch (error) {
            console.error("Error handling close chat click:", error);
        }
    }
    
    const longPressEvents = useLongPress(() => {
        setPersonOptions(true)
    }, 300);

    function handleOutsideClick() {
        setPersonOptions(false)
    }

    outsideClick(optionsRef, handleOutsideClick);

    return(
        <div onClick={() =>{try{props.onClick(props.user)}catch{}}}
            className={`${channelStyles.channel} ${props.isSelected ? channelStyles.channelSelected : ''}`}
            {...(props.mobile ? longPressEvents : {})}
        >
            <ProfilePicture small={true} inChat={true} user={props.user}/>
            <div className={channelStyles.usernameContainer}>
                <h2>{props.user.info.username}</h2>
                {props.mobile && (props.user.unreadCount == null || props.user.unreadCount == 0) &&
                    <div className={channelStyles.lastMessageContainer}>
                        <h4>{props.user.message.mine ? "You: " : "" }{shortenText(props.user.message.content)}</h4>
                        <h4>{formatTimestamp(props.user.message.timestamp)}</h4>
                    </div>
                }
                {props.user.unreadCount != null && props.user.unreadCount != 0 &&
                    <h4>{props.user.unreadCount} unread message{props.user.unreadCount != 1 && "s"}</h4>
                }
            </div>
            {(personOptions) &&
                <div ref={optionsRef} style={{height: "15vh"}} className={formStyles.mobileMessageOptionsMenu}>
                    <div className={formStyles.mobileMessageOptionsMenuItem} onClick={handleCloseChatClick}>
                        <li>Close Chat</li>
                    </div>
                </div>
            }            
            {!props.mobile && <CloseChat onClick={handleCloseChatClick} className={channelStyles.closeChat}/>}
        </div>
    )
}