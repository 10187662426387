import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {batch, connect} from "react-redux";

import MainContainer from "../../layout/parts/social/container/MainContainer";
import chatStyles from "../../../../css/layout/chat/chat.css";
import { Person } from "./Person";
import axios from "axios";
import { API_URL, SOCKET_GATEWAY } from "../../../../util/constants";
import useWindowDimensions from "../../../../util/hooks/useWindowDimensions";

import EmptyGraphic from "../../../../assets/graphics/chat/empty.svg";
import store from "../../../../store";
import history from "../../../../util/history";
import ChatHistory from "./ChatHistory";
import PersonPreview from "./PersonPreview";
import RecommendNav from "../../layout/parts/social/nav/right/RecommendNav";
import PortalNav from "../../layout/PortalNav";

import sidebarStyles from "../../../../css/layout/social/nav/sidebar.css";
import rightSidebarStyles from "../../../../css/layout/social/nav/right.css";
import searchStyles from "../../../../css/components/search.css";

function Chat(props){
    const { width } = useWindowDimensions();
    //give desktop version to tablet
    const mobile = width < 768;

    const [user, setUser] = useState(null)
    const [isLoadingChatList, setisLoadingChatList] = useState(true);
    const [isLoadingPersonPreview, setIsLoadingPersonPreview] = useState(true);

    let websocket = useRef(null)
    let currentChannelRef = useRef(null)
    let chatList = useRef(null)
    let chatHistory = useRef(null)
    let isDirectChatNavigation = useRef(null)
    let lastMessageBatchReqLength = useRef(null)

    let firstMessageID = useRef(null)
    let lastMessageID = useRef(null)
    let jumpedMessageID = useRef(null)

    let id = props.chat.currentChannel;
    if(!id){
        try{
            id = props.match.params.id;
        } catch(e){}
    }

    function highlightAndScrollToElement(messageElement) {
        setTimeout(() => {
            messageElement.scrollIntoView({ behavior: "smooth", block: "center" });
            messageElement.classList.add(searchStyles.highlightedMessage);
            setTimeout(() => {
                messageElement.classList.remove(searchStyles.highlightedMessage);
            }, 2000);
        }, 0);
    }

    function handleScrollToMessage(elementId, prepend, batch) {
        if(batch) {
            try {
                const element = document.getElementById(elementId);
                if (!element) {
                    throw new Error(`Element with ID ${elementId} not found`);
                }
                highlightAndScrollToElement(element);
            } catch (error) {
                console.error(`Error in highlightAndScrollToElement: ${error.message}`);
            }
        } else {
            try {
                document.getElementById(elementId).scrollIntoView({
                    behavior: "smooth",
                    block: prepend ? "start" : "end",
                });
            } catch (e) {
                console.log(e)
            }
        }
    }

    function performClosedChatFilter(closedChatsList, chatListUsers) {
        const closedChatsSet = new Set(closedChatsList);
        return chatListUsers.filter(user => !closedChatsSet.has(user.info.id));
    }

    function processAndDispatchChatHistory(data, reverse=false) {
        lastMessageBatchReqLength.current = data.history.length
        if(lastMessageBatchReqLength.current != 0) {
            let processedHistory = data.history.map(item => ({
                id: item.id,
                content: item.content,
                picture_url: `${API_URL}/social/avatar/${item.sender}`,
                mine: item.sender === props.auth.user.info.id,
                sent: true,
                deleted: item.deleted,
                attachments: item.attachments,
                timestamp: item.timestamp,
                parent: item.parent,
                edited: item.edited
            }));

            let element = null

            if(data.batch) {
                element = jumpedMessageID.current
            } else {
                if(reverse) {
                    element = processedHistory[0].id
                    processedHistory.reverse()
                } else if (data.prepend) {
                    element = chatHistory.current[0].id
                } else if (!data.prepend && (chatHistory.current.length != 0)) {
                    element = chatHistory.current[chatHistory.current.length - 1].id;
                }
            }

            const actionType = data.prepend ? "PREPEND_CHAT_HISTORY" : "APPEND_CHAT_HISTORY" 

            store.dispatch({ type: actionType, payload: processedHistory });       
            
            if(element != null) {
                handleScrollToMessage(element, data.prepend, data.batch)
            }

        }
    }
    
    useEffect(() => {
        chatHistory.current = props.chat.history
        if(props.chat.history.length != 0 && props.chat.performingJump) {
            lastMessageID.current = props.chat.history[props.chat.history.length - 1].id
            firstMessageID.current = props.chat.history[0].id
        }
    }, [props.chat.history])

    useEffect(() => {
        jumpedMessageID.current = props.chat.jumpedMessageID
    }, [props.chat.jumpedMessageID])
 
    useEffect(() => {
        if(props.chat.refreshChatList) {
          setisLoadingChatList(true)
          refreshChatList().then(() => {
              store.dispatch({type: "SET_REFRESH_CHAT_LIST", payload: false})
              setisLoadingChatList(false)
          })
        }
    }, [props.chat.refreshChatList])

    function getFirstLast(first = true) {
        return first ? firstMessageID.current : lastMessageID.current;
    }
    
    useEffect(() => {
        if(props.match.params.id != null){
            store.dispatch({type: "UPDATE_CURRENT_CHANNEL", payload: props.match.params.id})
            history.push("/chat")
        }
        currentChannelRef.current = props.chat.currentChannel
        if(props.chat.currentChannel != null){
            getPreviousHistory()
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Auth-Token": props.auth.token
            }
        }
        axios.get(`${API_URL}/users/profile/${id}`, config)
            .then(res => {
                setUser(res.data.data.user)
                setIsLoadingPersonPreview(false)
            })
            .catch(err => {
                setUser(null)
                setIsLoadingPersonPreview(true)
            })
    }, [props.chat.currentChannel, id])

    useEffect(() => {
        chatList.current = props.chat.chatList
    }, [props.chat.chatList])

    const config = {
        headers: {
            "Content-Type": "application/json",
            "Auth-Token": props.auth.token,
            "Client-Mobile": mobile ? true : false
        }
    }

    useEffect(() => {
        if(props.match.params.id != null && currentChannelRef.current != props.match.params.id){
            batch(() => {
                store.dispatch({type: "UPDATE_CHAT_LIST", payload: []})
                store.dispatch({type: "UPDATE_CURRENT_CHANNEL", payload: currentChannelRef.current || props.match.params.id})
                store.dispatch({type: "UPDATE_CHAT_HISTORY", payload: []})
            })
        } else{
            batch(() => {
                store.dispatch({type: "UPDATE_CHAT_LIST", payload: []})
                store.dispatch({type: "UPDATE_CHAT_HISTORY", payload: []})
            })
        }
        axios.get(API_URL + "/chat/list", config)
            .then(res => {
                let closedFilteredChatList = performClosedChatFilter(res.data.data.closedChatsList, res.data.data.users)
                store.dispatch({type: "UPDATE_CLOSED_CHATS_LIST", payload: res.data.data.closedChatsList})
                store.dispatch({type: "UPDATE_CHAT_LIST", payload: closedFilteredChatList})
                
                isDirectChatNavigation.current = (props.match.params.id != null)
                
                if(!mobile && !isDirectChatNavigation.current) {
                    if (props.chat.lastInteractedChannel !== null && 
                        props.chat.lastInteractedChannel != props.chat.currentChannel &&
                        closedFilteredChatList.length > 0
                        ) {
                        store.dispatch({type: "UPDATE_CURRENT_CHANNEL", payload: props.chat.lastInteractedChannel})
                    }
    
                    if (props.chat.lastInteractedChannel == null && closedFilteredChatList.length > 0) {
                        batch(()=>{
                            store.dispatch({type: "UPDATE_CURRENT_CHANNEL", payload: closedFilteredChatList[0].info.id})
                            store.dispatch({type: "SET_LAST_INTERACTED", payload: closedFilteredChatList[0].info.id})
                        })
                    }    
                } 
                setisLoadingChatList(false);
            })
            .catch(err => {
                console.log(err)
                setisLoadingChatList(false);
            })
        
        let wst = new WebSocket(`${SOCKET_GATEWAY}`)
        wst.onopen = () => {
            let login = {
                type: "login",
                id: props.auth.user.info.id,
                refresh_token: props.auth.refreshToken
            }
            wst.send(JSON.stringify(login))
        }
        wst.onmessage = (event) => {
            let data = JSON.parse(event.data)
            if(data.type == "login_confirmed"){
            } else if(data.type == "message"){
                manageChatListOnNewMessage(data)
                if(data.sender != currentChannelRef.current){
                    return
                }
                let message = {
                    id: data.id,
                    content: data.content,
                    picture_url: `${API_URL}/social/avatar/${currentChannelRef.current}`,
                    username: props.auth.user.info.username,
                    mine: false,
                    timestamp: data.timestamp,
                    attachments: data.attachments,
                    parent: data.parent
                }
                store.dispatch({type: "APPEND_CHAT_HISTORY", payload: message})
            } else if(data.type == "status"){
                if(data.status == "sent"){
                    store.dispatch({type: "MARK_MESSAGE_AS_SENT", payload: {temp_id: data.temp_id, id: data.id}})
                } else if(data.status == "failed"){
                    store.dispatch({type: "MARK_MESSAGE_AS_FAILED", payload: {temp_id: data.temp_id}})
                }
            } else if(data.type == "history"){
                processAndDispatchChatHistory(data)
            } else if(data.type == "certain_history"){
                processAndDispatchChatHistory(data, data.prepend)
            } else if(data.type == "typing"){
                if(data.sender != currentChannelRef.current){ return }
                store.dispatch({type: "UPDATE_TYPING", payload: {sender: data.sender, timestamp: Date.now() + 6000}})
            } else if(data.type == "delete"){
                store.dispatch({type: "DELETE_MESSAGE", payload: data.message_id})
            }
        };
        wst.onclose = () => {
        }

        websocket.current = wst

        return () => {
            websocket.current.close()
        }
    }, [])

    function manageChatListOnNewMessage(data){
        let exists = false;
        for(let user of chatList.current){
            if(user.info.id == data.sender){
                exists = true;
                break;
            }
        }

        if(!exists){
            axios.get(API_URL + "/users/profile/" + data.sender, config)
                .then(res => {
                    let user = res.data.data.user
                    user.unreadCount = 1
                    store.dispatch({type: "UPDATE_CHAT_LIST", payload: [user, ...chatList.current]})
                })
                .catch(err => {
                    console.log(err)
                })
        } else{
            let newChatList = []
            for(let user of chatList.current){
                if(user.info.id == data.sender){
                    if(currentChannelRef.current != data.sender){
                        if(!user.unreadCount){
                            user.unreadCount = 1
                        } else{
                            user.unreadCount += 1
                        }
                    }
                    newChatList.unshift(user)
                } else{
                    newChatList.push(user)
                }
            }
            store.dispatch({type: "UPDATE_CHAT_LIST", payload: newChatList})
        }
    }

    function sendMessage(message){
        websocket.current.send(JSON.stringify(message));
        
        //Optimize fix later for both recipient and receiver
        forceOpenChat(message.recipient)
        
        if(isDirectChatNavigation.current) {
            store.dispatch({type: "SET_LAST_INTERACTED", payload: message.recipient})
        }

        let updatedChatList = [...chatList.current];
        const userIndex = updatedChatList.findIndex(user => user.info.id === currentChannelRef.current);

        let user;
        if(userIndex > -1) {
            [user] = updatedChatList.splice(userIndex, 1);
            updatedChatList.unshift(user);
        } else {
            axios.get(`${API_URL}/users/profile/${currentChannelRef.current}`, config)
                .then(res => {
                    user = res.data.data.user;
                    user.unreadCount = 0;
                    updatedChatList.unshift(user);
                    store.dispatch({type: "UPDATE_CHAT_LIST", payload: updatedChatList});
                })
                .catch(err => {
                    console.log(err);
                });
            return;
        }

        store.dispatch({type: "UPDATE_CHAT_LIST", payload: updatedChatList});
    }

    function sendIsTyping(){
        let payload = {
            type: "typing",
            id: props.auth.user.info.id,
            recipient: currentChannelRef.current,
            refresh_token: props.auth.refreshToken
        }
        websocket.current.send(JSON.stringify(payload))
    }

    function getPreviousHistory(useOffset=false, messageOffset=null, fromJumpToPresent=false){
        if (lastMessageBatchReqLength.current != 0 || fromJumpToPresent) {
            const sendPayload = () => {
                let payload = {
                    type: "get_history",
                    id: props.auth.user.info.id,
                    recipient: props.chat.currentChannel,
                    offset: useOffset ? props.chat.history.length : messageOffset ? messageOffset : 0,
                    refresh_token: props.auth.refreshToken
                };
                websocket.current.send(JSON.stringify(payload));
            };
    
            let attempts = 0;
            const sendWithRetry = () => {
                if(attempts >= 5){
                    return;
                }
                try{
                    sendPayload();
                } catch(error){
                    attempts++;
                    setTimeout(sendWithRetry, 200);
                }
            };
    
            sendWithRetry();
        }
    }

    function getCertainHistory(messageID, messageDirection) {
        const sendPayload = () => {
            let payload = {
                type: "get_certain_history",
                id: props.auth.user.info.id,
                recipient: props.chat.currentChannel,
                refresh_token: props.auth.refreshToken,
                message_id: messageID,
                direction: messageDirection,
            };
            websocket.current.send(JSON.stringify(payload));
        };

        let attempts = 0;
        const sendWithRetry = () => {
            if(attempts >= 5){
                return;
            }
            try{
                sendPayload();
            } catch(error){
                attempts++;
                setTimeout(sendWithRetry, 200);
            }
        };

        sendWithRetry();
    }

    function refreshChatList() {
        return new Promise((resolve, reject) => {
            axios.get(API_URL + "/chat/list", config)
                .then(res => {
                    chatList.current = performClosedChatFilter(res.data.data.closedChatsList, res.data.data.users)
                    batch(() => {
                        store.dispatch({type: "UPDATE_CLOSED_CHATS_LIST", payload: res.data.data.closedChatsList})
                        store.dispatch({type: "UPDATE_CHAT_LIST", payload: chatList.current})
                        if(chatList.current.length >= 1 && !mobile) {
                            store.dispatch({ type: "UPDATE_CURRENT_CHANNEL", payload: chatList.current[0].info.id });
                            store.dispatch({ type: "SET_LAST_INTERACTED", payload: chatList.current[0].info.id });
                        } else {
                            store.dispatch({ type: "UPDATE_CURRENT_CHANNEL", payload: null });
                            store.dispatch({ type: "SET_LAST_INTERACTED", payload: null });
                        }
                    });
                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    
    const handleClick = useCallback((user) => {
        if (props.chat.currentChannel === user.info.id) return;
        batch(() => {
            store.dispatch({ type: "SET_UNREAD_COUNT", payload: { id: user.info.id, count: 0 } });
            store.dispatch({ type: "UPDATE_CHAT_HISTORY", payload: [] });
            store.dispatch({ type: "UPDATE_CURRENT_CHANNEL", payload: user.info.id });
            store.dispatch({ type: "SET_LAST_INTERACTED", payload: user.info.id });
        });
    }, [props.chat.currentChannel, store]);

    const handleDelete = useCallback((id) => {
        let payload = {
            id: props.auth.user.info.id,
            refresh_token: props.auth.refreshToken,
            message_id: id,
            recipient: currentChannelRef.current,
            type: "delete",
        };
    
        websocket.current.send(JSON.stringify(payload));
    }, [props.auth.user.info.id, props.auth.refreshToken, currentChannelRef.current, websocket.current]);

    const toggleChat = (chatChannel, data, callback) => {
        axios.post(`${API_URL}/chat/toggle/${chatChannel}`, data, config)
            .then(response => {
                if (response.status === 200) {
                    if (callback) callback();
                }
            })
    };
    
    const handleCloseChat = (chatChannel) => {
        toggleChat(chatChannel, {}, refreshChatList);
    };
    
    const forceOpenChat = (chatChannel) => {
        toggleChat(chatChannel, { toggle: false });
    };

    return(
        <MainContainer isDesktopChat={!mobile} chatUser={user} mobileChat={mobile} cover={false}>
            <div className={chatStyles.chatContainer} style={mobile && user == null ? {paddingBottom: "23%"} : null}>
                {!mobile || (mobile && props.chat.currentChannel == null) ?
                    <div className={chatStyles.leftChannelListDiv} style={{overflowX: mobile && "hidden"}}>
                        <div className={chatStyles.leftChannelList}>
                            {!isLoadingChatList ? 
                                props.chat.chatList.length > 0 ?
                                    props.chat.chatList.map(user => (
                                        <Person 
                                            onClick={handleClick}
                                            onCloseChat={handleCloseChat} 
                                            user={user} 
                                            chat={user.chat} 
                                            isSelected={props.chat.currentChannel === user.info.id}
                                            mobile={mobile}
                                        />
                                    ))
                                    :
                                <div className={chatStyles.emptyDiv} >
                                    <h1>No chats yet!</h1>
                                    <h3>Start a new chat on a user's profile.</h3>
                                    <div style={{height: "25px"}}></div>
                                    <EmptyGraphic width="75%"/>
                                </div>                                
                            : 
                                <div className={chatStyles.emptyDiv} >
                                    <h3>...</h3>
                                    <div style={{height: "25px"}}></div>
                                </div>
                            }
                        </div>
                    </div>
                :null
                }
                {(!mobile || (mobile && props.chat.currentChannel != null)) && user != null ?
                    <>
                    <ChatHistory
                        fetchPreviousMessages={getPreviousHistory}
                        getCertainHistory={getCertainHistory}
                        getFirstLast={getFirstLast}
                        sendMessage={sendMessage}
                        user={user}
                        sendIsTyping={sendIsTyping}
                        handleDelete={handleDelete}
                        highlightAndScrollToElement={highlightAndScrollToElement}
                    />
                    {props.chat.personPreview && width > 1200 && <PersonPreview user={user} />}
                    </>
                    :null
                }
            </div>
            {!mobile && props.chat.personPreview ?
                isLoadingChatList || isLoadingPersonPreview && props.chat.chatList.length != 0 && !isDirectChatNavigation.current ?
                    <div className={sidebarStyles.sidebarContainer + ' ' + rightSidebarStyles.sidebarContainer}>
                        <div style={{background: "var(--sidebar-active)" }} className={sidebarStyles.sidebar + ' ' + rightSidebarStyles.sidebar}>
                            <div style={{marginLeft: "17px", marginTop: "25px"}}>
                                <h3 style={{textAlign: 'center', margin: '20px 0' }}>...</h3>
                            </div>
                        </div>
                    </div>                
                    :
                    user == null && <RecommendNav />
                :
                null           
            }
            {mobile && user == null && <PortalNav showMobile={true} mobileChat={true} />}       
        </MainContainer>
    )
}

const mapStateToProps = (state) => {
    return { auth: state.auth, chat: state.chat}
};

export default connect(mapStateToProps)(Chat);
