import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactTooltip from "react-tooltip";

import { API_URL } from "../../../../../util/constants";

function FixedImageAd(props){
    const [link, setLink] = useState(null);
    const [image, setImage] = useState(null);

    useEffect(() => {
        axios.get(`${API_URL}/ads/random`).then((res) => {
            setLink(res.data.data.link);
            setImage(res.data.data.file);
        }).catch((err) => {
            console.error(err);
        });
    }, []);

    function click(){
        if(link){
            window.open(link, "_blank")
        }
    }

    return (
        image !== null &&
        <div style={{display: "flex", justifyContent: "center"}}>
            <div
                style={{
                    width: "300px",
                    height: "250px",
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center"
                }}
                data-tip={link}
            >
                {image !== null &&
                    <>
                        <img
                        onClick={click}
                            src={`${API_URL}/ads/file/${image}`}
                            style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "cover"
                            }}
                        />
                        <ReactTooltip delayShow={500} />
                    </>
                }
            </div>
        </div>
    )
}

export default FixedImageAd;